const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/Base');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Portfolio = () => import(/* webpackChunkName: "home" */'../views/Portfolio');
const Offer = () => import(/* webpackChunkName: "home" */'../views/Offer');
const Contact = () => import(/* webpackChunkName: "home" */'../views/Contact');

export default {
    path: '/',
    name: 'DashboardLayout',
    component: Layout,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/portfolio/',
            name: 'Portfolio',
            component: Portfolio
        },
        {
            path: '/offer/',
            name: 'Offer',
            component: Offer
        },
        {
            path: '/contact/',
            name: 'Contact',
            component: Contact
        }
    ]
};
