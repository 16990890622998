import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import AOS from 'aos';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

import VueScrollTo from 'vue-scrollto';
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta';
import './assets/style.scss';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(CoolLightBox);
Vue.use(VueScrollTo);
Vue.use(VueMeta);

Vue.use(VueAnalytics, {
    id: 'UA-XXX-X'
});

new Vue({
    created () {
        AOS.init();
    },
    router,
    render: h => h(App)
}).$mount('#app');
